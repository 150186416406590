import React, {useState} from 'react';
import {ThemeProvider} from 'styled-components';
import Whole__Page from "./Pages/7Studio";
import {BrowserRouter} from "react-router-dom";
import '../src/i18n'
import {ANIMATION__CONTEXT, Local_Data} from "./Context";
import {Local__Data__Value} from "./PageValues";
import Animation from "./Pages/Compnents/Animation";


function App() {

  const [localData, setLocalData] = useState(Local__Data__Value)

  const [animation, setAnimation] = useState(false)

  const LIGHT_THEME = {
    OfferListBackgroundColor: 'rgba(255,255,255,0.15)',
    isDark: true,
    selectTagsBackgroundColor: 'rgb(145, 145, 145)',
    toolTipColor: 'grey',
    focusedInputBorderColor: '#1a9fff',
    optionHoverColor: '#424242',
    calendarSelectedDate: '#000000',
    calendarFillDates: '#4f4f4f',
    placeholderColor: '#868686',
    backgroundColor: "#000000",
    borderColor: "white",
    borderColorLight: '#2c2c2c',
    separatorColor: "white",
    color: "#f3f3f3",
    buttonBackgroundColor: "#537517",
    typeOfSearchHover: 'rgba(0,0,0,0.5)',
    colorBlue: '#0000c9',
    disabledFontColor: 'grey',
    isCheckedSwitchGreen: '#097705',
    ratingStarColor: '#d79b1f',
    circleInfoColor: '#429ddc',
    successMessageBackgroundColor: "rgba(50,164,54,0.9)",
    errorMessageBackgroundColor: 'rgba(255,48,48,0.9)',
    errorMessageIconColor: 'rgba(255,48,48,1)',
    flameIconColor:'#e25822',
    warningBackgroundColor: "rgba(241,196,105,0.9)",
    brdExclusiveColor: '#3ea3f8',
    missingRatingColor: '#575757'
  }

  return (
      <Local_Data.Provider value={[localData, setLocalData]}>
        <ANIMATION__CONTEXT.Provider value={[animation, setAnimation] as any}>
          <ThemeProvider theme={LIGHT_THEME}>
            <BrowserRouter>
               <Whole__Page/>
               <Animation/>
            </BrowserRouter>
          </ThemeProvider>
        </ANIMATION__CONTEXT.Provider>
      </Local_Data.Provider>
);
}

export default App;
