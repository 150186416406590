import { Styled } from "./MainPage.styled"
import NavigationBox from "../Compnents/NavigationBox";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import { RENT_PAGE, STUDIO_PAGE} from "../../Routes";
import {useEffect, useState} from "react";
import useS115 from "../../lib/Hooks/useS115";

const MainPage = () =>{

    const {t} = useTranslation()


    const { navigatePage }=useS115()

    const [imageIndex, setImageIndex] = useState<number>(0)

    const imageList : string[] = [
        'https://firebasestorage.googleapis.com/v0/b/studioskar.appspot.com/o/IMG_4373.JPG?alt=media&token=7aed5358-624b-4560-942d-86eb7def65ed',
        'https://firebasestorage.googleapis.com/v0/b/studioskar.appspot.com/o/IMG_4375.JPG?alt=media&token=5851d4b4-6174-41bf-8553-36bf03cd8e6e',

    ]

    useEffect(() => {
        const intervalId = setInterval(() => {
            setImageIndex((prevNumber) => (prevNumber + 1) % imageList.length);
        }, 5000);
        return () => clearInterval(intervalId);
    }, []);

    return(
        <Styled.MainPage>
            <NavigationBox
                onClick={()=>{
                 navigatePage(RENT_PAGE)
                }}
                sectionLabel={t('lbl_rent_equipment')}
                src={imageList[imageIndex]}
                type={'image'}
            />
            <NavigationBox
                onClick={()=>{
                    navigatePage(STUDIO_PAGE)
                    }}
                sectionLabel={t('lbl_studio')}
                src={'https://firebasestorage.googleapis.com/v0/b/studioskar.appspot.com/o/s115stronastudio%20(1).mp4?alt=media&token=e3446e9e-8ca9-4b15-a5c6-879a56545463'}
                type={'video'}
            />
        </Styled.MainPage>
    )

}
export default MainPage