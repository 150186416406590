import { Styled } from "./RentPageList.styles"
import {useContext} from "react";
import {Local_Data} from "../../Context";
import Product from "./Product";
import product from "./Product";

const RentPageList = () =>{

    const [data] = useContext<any>(Local_Data)

    const {Products} = data

    return(
        <Styled.Page__Holder>
            {Object?.keys(Products)?.map((product:any)=>{{
                const value = Products[product]
                return(
                    <Product
                        label={product}
                         value={value}
                    />
                )
            }})}
        </Styled.Page__Holder>
    )
}
export default RentPageList