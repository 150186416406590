import {Styled} from './Button.styles';
import React from "react";
import {useTranslation} from "react-i18next";
import FlexBox from '../FlexBox';
import {isNumber, isString} from "lodash-es";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

type ButtonProps = {
    error?: boolean
    onClick?: (event: any) => void
    onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void
    onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void
    onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void
    ref?: any
    type?: string
    style?: React.CSSProperties
    label?: string | number | undefined | IconProp
}
const defaultProps = {
    error: false,
    type: 'normal',
    onClick: undefined,
    onMouseOver: undefined,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
    ref: undefined,
    label: 'Insert Name',
}

const Button = ({label, type, error, ref, ...props}: ButtonProps) => {
    const {t} = useTranslation();
    return (
        <Styled.Button
            error={!!error}
            ref={ref}
            {...props}
        >
            <FlexBox
                style={{
                    userSelect: 'none',
                    height: '100%',
                    width: '100%'
                }}
            >
                {isString(label) || isNumber(label) ? (
                    <Styled.CustomText>
                        {label}
                    </Styled.CustomText>
                ) : (
                    <>
                        <Styled.FontAwesomeCustomIcon
                            icon={label as IconProp}
                        />
                    </>
                )}
            </FlexBox>
        </Styled.Button>
    )
}
Button.defaultProps = defaultProps;

export default Button