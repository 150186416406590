import { Styled } from "./NavigationBox.styles"
import React, {useMemo, useState} from "react";
import {faImages} from "@fortawesome/pro-solid-svg-icons";

type NavigationBoxProps = {
    sectionLabel?: string
    onClick?: ()=>void
    styles?: React.CSSProperties
    type?: 'image'| 'video'
    src?: string
}

const NavigationBox = (
    {
        sectionLabel,
        onClick,
        styles,
        type,
        src
    }:NavigationBoxProps
) =>{

    const [isHover, setIsHover]  = useState<boolean>(false)

    const getContent = () =>{

        switch (type){
            case 'image':
                return(
                    <Styled.Navigation__Image
                        customLoader={faImages}
                        isHover={isHover}
                        src={src as any}
                    />
                )
            case 'video':
                return (
                    <Styled.Navigation__Video
                        isHover={isHover}
                        loop
                        autoPlay
                        muted

                    >
                        <source src={src} type={'video/mp4'} />
                    </Styled.Navigation__Video>
                )
        }
    }

    return useMemo(()=>(
        <Styled.Navigation__Box__Values__Holder
        style={styles}
        >
            {getContent()}

            <Styled.Control__Section__Navigation__Box
                onMouseEnter={()=>{if (!isHover){
                    setIsHover(true)
                }}}
                onMouseLeave={()=>{if (isHover){
                    setIsHover(false)
                }}}
             onClick={onClick}
            >
            <Styled.Section__Text>
                {sectionLabel}
            </Styled.Section__Text>
            </Styled.Control__Section__Navigation__Box>
        </Styled.Navigation__Box__Values__Holder>
    ),[])
}
export default NavigationBox