import {useParams} from "react-router";
import {useContext, useState} from "react";
import {Local_Data} from "../../Context";
import {Styled} from "./Productpage.styles";
import {useTranslation} from "react-i18next";
import {CONTACT_PAGE} from "../../Routes";
import Button from "../../lib/UIBookBrd/Button";
import Text from "../../lib/UIBookBrd/Text";
import {descriptions} from "../../PageValues";
import {useLocalStorage} from "react-use-storage";
import useS115 from "../../lib/Hooks/useS115";

const ProductPage = () => {

    const {t} = useTranslation()

    const {navigatePage} = useS115()

    const [language, setLanguage] = useLocalStorage('language', 'pl')

    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0)

    const {productName} = useParams();

    const [data] = useContext<any>(Local_Data)

    const {Products} = data as any

    const ProductValue = Products[`${productName}`]

    const images = ProductValue.imageGallery ?? []

    const onReserveClick = (event: any) => {
        event.stopPropagation()
        navigatePage(CONTACT_PAGE)
    }

    return (
        <Styled.Page__Hodler>
            <Styled.Header>
                <Styled.Main__Values__Holder>
                    <Styled.Product__Title>
                        {`${!ProductValue?.specialName ? productName : t(`lbl_${productName}`)} ${ProductValue?.producent ? `• ${ProductValue?.producent}` : ''}`}
                    </Styled.Product__Title>
                </Styled.Main__Values__Holder>
                <Styled.Images__Holder>
                    <Styled.Main__Image
                        src={images[currentImageIndex]}
                    />
                    <Styled.Small__Images__Holder>
                        {images?.map((image: string, index: any) => {
                            return (
                                <Styled.Small__Image
                                    onClick={() => setCurrentImageIndex(index)}
                                    src={image}
                                />
                            )
                        })}
                    </Styled.Small__Images__Holder>
                </Styled.Images__Holder>
                <Styled.Product__Essential__Values>
                    <Styled.Price__And__Count__Holder>
                        <Text>
                            {t('lbl_available_count')}{': '}{ProductValue.count}
                        </Text>
                        <Styled.Price__Label>
                            {`${ProductValue?.rentPrice} PLN ${t('lbl_rent_day')}`}
                        </Styled.Price__Label>
                    </Styled.Price__And__Count__Holder>
                    <Button
                        label={t('lbl_reserve_product')}
                        onClick={onReserveClick}

                        style={{
                            minWidth: '180px',
                            maxWidth: '250px',
                            marginTop: 'auto',
                            marginLeft: 'auto',
                            fontWeight: 'bold'
                        }}
                    />
                </Styled.Product__Essential__Values>
                <Styled.Section__Title>
                    {t('lbl_short_product_description')}
                </Styled.Section__Title>
                <Text
                    style={{
                        padding: ' 0 10px'
                    }}
                >
                    {t(`lbl_${productName}_desc`)}
                </Text>
                {descriptions[productName as any] && (
                    <>
                        <Styled.Section__Title>
                            {t('lbl_long_product_description')}
                        </Styled.Section__Title>
                        <Text>
                            <ul>
                                {descriptions[productName as any] && descriptions[productName as any][language]?.map((item: any) => (
                                    <li>{item}</li>))}
                            </ul>
                        </Text>
                    </>
                )}
            </Styled.Header>
        </Styled.Page__Hodler>
    )
}

export default ProductPage