import {useContext} from "react";
import {Local_Data} from "../../Context";
import {ABOUT_US_PAGE, CONTACT_PAGE, MAIN_PAGE, RENT_PAGE, STUDIO_PAGE} from "../../Routes";
import {useTranslation} from "react-i18next";



const getSiteXML = (paths: any) => {
    const DefaultPRIO = 0.5
    const DefaultFreq = 'monthly'

    const urls = Object.keys(paths).map((path: any) => {
        const value = paths[path]

        const url = `https://s115.pl${path}`;
        const lastmod = '2023-11-21T00:00:00.000Z';
        const priority = value.priority || DefaultPRIO;
        const changefreq = value.changeFreq || DefaultFreq;

        return `
      <url>
        <loc>${url}</loc>
        <lastmod>${lastmod}</lastmod>
        <priority>${priority}</priority>
        <changefreq>${changefreq}</changefreq>
      </url>
    `;
    });

    return `
    <?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${urls.join('')}
    </urlset>
  `;
};

const useMetaForS115 = () =>{
    const [data] = useContext<any>(Local_Data)

    const {Products} = data

    const BASE_TITLE = 'S115 | '

    const mapForMetaTagsSEO :any ={
        [MAIN_PAGE] : {
            priority: 1,
            title: BASE_TITLE + 'Strona główna',
            keywords: 'S115, wypożyczalnia sprzętu audio dla Dj Wrocław Polska, Wynajem DJ Wrocław, Studio Dj Wrocław, Studio dla DJ Wrocław, Graj na profesjonalnym sprzęcie DJ we Wrocławiu, sprzęt pionner, Profesjonalny sprzęt DJ, Rave sprzęt',
            description: 'Wypożyczalnia sprzętu audio i studio w Wrocławiu. Profesjonalny sprzęt dla DJ i nie tylko.',
        },
        [RENT_PAGE]: {
            priority: 0.9,
            title: BASE_TITLE + 'Wynajem sprzętu audio',
            keywords: 'S115, wynajem sprzętu audio dla DJ, sprzęt pionner na wynajem, wynajem dla DJ Wrocław, wynajem sprzetu audio, tani wynajem sprzętu audio dla DJ',
            description: 'Wynajmij sprzęt audio w dobrych cenach, mamy sprzęt takich marek jak PIONNER, Yamaha, Shure i wiele innych'
        },
        [ABOUT_US_PAGE] : {
            priority: 0.8,
            title: BASE_TITLE + 'O nas',
            keywords: 'S115, Poznaj S115, dowiedz sie wiecej o s115, co to jest s115, skąd jest s115 , dlaczego akurat s115',
            description: 'Odkryj naszą pasję! Dowiedz się więcej o naszej wypożyczalni sprzętu audio i profesjonalnym studiu muzycznym we Wrocławiu.'
        },
        [CONTACT_PAGE]: {
            priority: 0.8,
            title : BASE_TITLE + "Kontakt",
            description: 'Skontaktuj sie z nami aby dowiedzieć sie o dostępności produtku bądź by sprostować wątpliwości',
            keywords: "Skontaktuj sie z s115, zarezerwuj sprzet audio w s115, zadzwon do s115, napisz do s115, mail do s115"
        },
        [STUDIO_PAGE]:{
            priority: 0.9,
            title: BASE_TITLE + 'Nasze Studio',
            keywords: 'S115, Studio DJ, Studio nagrywania, poćwicz granie na profesjonalnym sprzęcie, graj na pionner jak profesjonalista',
            description: 'Odwiedź nasze studio i sam sie przekonaj jakie możliwości otwiera najlepszy sprzętem w branży audio.'
        }

    }

    Object?.keys(Products)?.map((product:any)=>{
        const value = Products[product]
        const {type, producent} = value
        mapForMetaTagsSEO[RENT_PAGE + '/' + product] = {
            priority: 0.7,
            title: BASE_TITLE + product.split('_').join(' '),
            description: type !== 'Setup' ? `Wynajem urządzenia ${product} marki ${producent}` : `Wynajmij ${product.split('_').join(' ')}`,
            keywords: `Wynajem sprzętu wrocław, sprzet dla DJ, produkt ${ type !== 'Setup' ? product :  product.split('_').join(' ')}`
        }
       return
    })

    const DEFAULT_TITLE = 'S115'

    const DEFAULT_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/s115-c0649.appspot.com/o/s115_background.png?alt=media&token=f7a19778-bf9c-4dff-aeba-56ce2f9fdcd9'

    const DEFAULT_KEYWORDS = 'S115, wypożyczalnia sprzętu audio dla Dj Wrocław Polska, Wynajem DJ Wrocław, Studio Dj Wrocław, Studio dla DJ Wrocław, Graj na profesjonalnym sprzęcie DJ we Wrocławiu, sprzęt pionner, Profesjonalny sprzęt DJ, Rave sprzęt'

    const DEFAULT_DESCRIPTION = 'Wypożyczalnia sprzętu audio i studio w Wrocławiu. Profesjonalny sprzęt dla DJ i nie tylko.'



    const getMetaForSEO = () => {
        const ogTitleTag = document.querySelector('meta[property="og:title"]') || document.createElement('meta');
        const keywordsTag = document.querySelector('meta[property="keywords"]') || document.createElement('meta');
        const ogDescription = document.querySelector('meta[property="og:description"]') || document.createElement('meta');
        const descriptionTag = document.querySelector('meta[name="description"]') || document.createElement('meta');
        const ogImage = document.querySelector('meta[property="og:image"]') || document.createElement('meta');
        const pathName = window.location.pathname
      const {title, description, keywords, image} = mapForMetaTagsSEO[pathName] ?? {}
        document.title = title ?? DEFAULT_TITLE
        ogDescription.setAttribute('property', 'og:description')
        ogImage.setAttribute('property', 'og:image')
        ogTitleTag.setAttribute('property', 'og:title')
        keywordsTag.setAttribute('property', 'keywords');
        descriptionTag.setAttribute('name', 'description');
        ogImage.setAttribute('content', image ?? DEFAULT_IMAGE)
        keywordsTag.setAttribute('content', keywords ?? DEFAULT_KEYWORDS);
        ogDescription.setAttribute('content', description ?? DEFAULT_DESCRIPTION)
        descriptionTag.setAttribute('content', description ?? DEFAULT_DESCRIPTION);
    }
    return({
        getMetaForSEO
    })
}

export default useMetaForS115