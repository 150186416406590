import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Image from "../../../lib/UIBookBrd/Image";
import Text from "../../../lib/UIBookBrd/Text";
import {rgba} from "polished";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Languages__Holder = styled(FlexBox)`
  border-radius: 10px;
  width: 100%;
`
const Language__Option = styled(FlexBox)`
  padding: 8px 12px;
  margin: 4px 6px;
  border-radius: 5px;
  cursor: pointer;
  min-width: 150px;
flex-wrap: nowrap;
  width: calc(25% - 36px);
  &:hover{
    background-color: ${({theme})=>rgba(theme.color, 0.3)};
  }
`
const Language__Image =styled(Image)`
border-radius: 50%;
  height: 30px;
  min-width: 30px;
  width: 30px;
`
const Language__Label = styled(Text)`
  margin: auto 0 auto 5px;
    font-size: 16px;
  font-weight: bold;
`
const Language__Icon = styled(FontAwesomeIcon)`
height: 20px;
  width: 20px;
  margin: auto 0 auto auto;
  color: ${({theme})=>theme.color};
`

export const Styled = {
    Language__Icon,
    Language__Label,
    Language__Image,
    Language__Option,
    Languages__Holder,
}