import Drawer from "../../../lib/UIBookBrd/Drawer";
import {Styled} from "./MenuDrawer.styles";
import {useContext} from "react";
import {Local_Data} from "../../../Context";
import {useTranslation} from "react-i18next";
import {CONTACT_PAGE, MAIN_PAGE, RENT_PAGE, STUDIO_PAGE} from "../../../Routes";
import useS115 from "../../../lib/Hooks/useS115";
import SingleOption from "./SingleOption";

const MenuDrawer = () => {

    const [localData, setLocalDate] = useContext<any>(Local_Data);

    const {t} = useTranslation()

    const {navigatePage} = useS115()

    const onClose = () => {
        setLocalDate((state: any) => ({
            ...state,
            isMenuOpen: false
        }))
    }

    const settingsList = [
        {
            label: t('lbl_change_language'),
            action: () => {
                setLocalDate((state: any) => ({
                    ...state,
                    isOpeLanguage: true,
                    isMenuOpen: false
                }))
            }
        }
    ]

    const logo = (
        <Styled.Page__Title
            onClick={() => {
                navigatePage(MAIN_PAGE)
                onClose()
            }}
        >
            S115
        </Styled.Page__Title>
    )

    return (
        <Drawer
            isOpen={localData?.isMenuOpen ?? false}
            position={'left'}
            title={logo}
            onClose={onClose}
        >
            <Styled.Menu__Drawer__Container>
                <SingleOption
                    title={t('lbl_rent_equipment')}
                    path={RENT_PAGE}
                />
                <SingleOption
                    title={t('lbl_studio')}
                    path={STUDIO_PAGE}
                />
                <SingleOption
                    title={t('lbl_contact')}
                    path={CONTACT_PAGE}
                />
            </Styled.Menu__Drawer__Container>
        </Drawer>
    )

}
export default MenuDrawer