import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Text from "../../lib/UIBookBrd/Text";

const About__Holder = styled(FlexBox)`
flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 73px;
  background-color: ${({theme})=>theme.backgroundColor}
`
const About__Title = styled(Text)`
    padding: 10px 5px;
  font-size: 25px;
  font-weight: bold;
`
const About__Us__Description = styled(Text)`
    font-size: 16px;
  line-height: 19px;
  padding: 10px 16px ;
`
const About__Us__Section__Label = styled(Text)`
  padding: 10px 5px;
  padding-bottom: 0;
  font-size: 20px;
  font-weight: bold;
`

export const Styled = {
    About__Us__Section__Label,
    About__Us__Description,
    About__Title,
    About__Holder
}