import styled from "styled-components";
import Image from "../../lib/UIBookBrd/Image";
import Button from "../../lib/UIBookBrd/Button";
import Text from "../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FlexBox from "../../lib/UIBookBrd/FlexBox";

const Page__Holder = styled.div`
  margin-top: 73px;
    display: flex;
  width: 100%;
  flex-direction: column;
`
const Product  = styled.div`
display: flex;
  background-color: ${({theme})=>theme.backgroundColor};
  border: 1px solid ${({theme})=>theme.color};
  border-radius: 5px;
  box-sizing: border-box;
  flex-wrap: nowrap;
  padding: 10px;
  width:calc(100% - 10px) ;
height: auto;
  margin: 5px;
  cursor: pointer;
  @media(max-width: 700px){
    height: auto;
    flex-direction: column-reverse;
  }
`
const Product__Information__Holder = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 2px 10px;
  width:calc(100% - 300px);
  @media(max-width: 700px){
    width: 100%;
    padding: 2px;
  }
`
const Product__Image = styled(Image)`
height: 200px;
  width: 300px;
  background-color: ${({theme})=>theme.backgroundColor};
  border-radius: 5px;
  object-fit: contain;
  @media(max-width: 700px){
    width: 100%;
  }
`
const Product__Label= styled.div`
  display: flex;
  flex-direction: initial;
  font-size: 25px;
  font-weight: bold;
color: ${({theme})=>theme.color};
`
const Price__Label = styled(Text)`
  font-size: 20px;
  margin-top: auto;
  margin-left: auto;
  
`
const Reserve__Button = styled(Button)`
  margin-left: auto;
  margin-right: 10px;
  min-width: 125px;
  height: 33.5px;
  @media(max-width: 700px){
  margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
  }
`
const Reserve__Label = styled.div`
margin: auto;
  font-size: 20px;
  padding: 5px 10px;
  font-weight: bold;
  color: ${({theme})=>theme.color};

`
const Producent__Label = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${({theme})=>theme.color};
`
const Price__Button__Holder = styled.div`
  display: flex;
  margin-top: auto;
  gap: 5px 0;
  flex-direction: column;
  @media(max-width: 700px){
flex-direction: column;
  }
`
const Short__Description = styled(Text)`
  font-size: 18px;
  padding: 10px 0;
  @media(max-width: 700px){

  }
`
const Flame__Price = styled(FontAwesomeIcon)`

color: ${({theme})=>theme.flameIconColor};
`
const Price__Value__Holder = styled.span<{isSpecialPrice: boolean}>`
  font-weight: bold;
  font-size: 20px;
  color: ${({theme, isSpecialPrice})=>isSpecialPrice ?theme.flameIconColor :theme.color};
  
`
const AvailAble__Count  = styled(Text)`
margin-left: auto;
`
export const Styled = {
    AvailAble__Count,
    Price__Value__Holder,
    Flame__Price,
    Price__Button__Holder,
    Producent__Label,
    Short__Description,
    Reserve__Label,
    Reserve__Button,
    Price__Label,
    Product__Label,
    Page__Holder,
    Product__Image,
    Product,
    Product__Information__Holder
}