import { Styled } from "./Studio.styles";
import {useTranslation} from "react-i18next";
import Text from "../../lib/UIBookBrd/Text";


const Studio = () =>{

    const {t} = useTranslation()

    return(
        <Styled.Page__Hodler>
            <Styled.Content__Holder>
                <Styled.Studio__Image
                src={'https://firebasestorage.googleapis.com/v0/b/studioskar.appspot.com/o/Screenshot%202023-11-22%20at%2014.02.59.png?alt=media&token=4c874c1a-2f73-4926-bb06-7bb6121d85a4'}
                />
                <Styled.H2__Studio>
                    {t('lbl_welcome_to_our_studio')}
                </Styled.H2__Studio>
                <Styled.Studio__Text>
                    {t('lbl_studio_first_line')}
                </Styled.Studio__Text>
                <Styled.Studio__Text>
                    {t('lbl_studio_second_line')}
                </Styled.Studio__Text>
                <Styled.Studio__Text>
                    {t('lbl_studio_third_line')}
                    <a
                        href={'https://www.instagram.com/s115studio/?hl=pl'}
                        target={'_blank'}
                        style={{
                            fontWeight:'bold',
                            color:"inherit",
                            textDecoration:'underline',
                            margin:'0 3px'
                        }}
                    >
                        Instagram
                    </a>
                    {t('lbl_or')}
                    <a
                        href = "mailto: studio@s115.pl"
                        target={'_blank'}
                        style={{
                            fontWeight:'bold',
                            color:"inherit",
                            textDecoration:'underline',
                            margin:'0 3px'
                        }}
                    >
                        Mail
                      </a>
                </Styled.Studio__Text>
                <br/>
                <Text style={{fontWeight:'bold', margin:' 0 3px'}}>
                    TAX:
                </Text>
                <Styled.Studio__Text>
                    {t('lbl_studio_pricing')}
                </Styled.Studio__Text>
                <br/>
                <Text style={{fontWeight:'bold', margin:' 0 3px'}}>
                    {t('lbl_b2b_dj')}
                </Text>
                <Styled.Studio__Text>
                    {t('lbl_studio_pricing_b2b')}
                </Styled.Studio__Text>
                <br/>
                <Styled.Studio__Text>
                    {t('lbl_studio_fourth_line')}
                </Styled.Studio__Text>
                <Styled.Studio__Text>
                    {t('lbl_studio_fifth_line')}
                </Styled.Studio__Text>
            </Styled.Content__Holder>
        </Styled.Page__Hodler>
    )
}
export default Studio