import {useContext} from "react";
import {ANIMATION__CONTEXT} from "../../Context";
import {useNavigate} from "react-router";
import {RENT_PAGE} from "../../Routes";

const useS115 = () =>{

    const [_, setIsAnimationOn]= useContext<any>(ANIMATION__CONTEXT)

    const navigate = useNavigate()

    const handleAnimation = () => {
        setIsAnimationOn(true)
        setTimeout(() => {
            setIsAnimationOn(false)
        }, 1200)
    }

    const navigatePage = (path: string) =>{
        handleAnimation()
        setTimeout(()=>{
            window.scrollTo(0,0)
            navigate(path)
        }, 400)
    }

    return({
        handleAnimation,
        navigatePage
    })
}


export default useS115