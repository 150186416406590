import {Styled} from "./MenuDrawer.styles"
import {useContext} from "react";
import {Local_Data} from "../../../Context";
import useS115 from "../../../lib/Hooks/useS115";

type SingleOptionProps = {
    title: string,
    path: string
}
const SingleOption = (
    {
        title,
        path
    }: SingleOptionProps) => {


    const [localData, setLocalDate] = useContext<any>(Local_Data);

    const {navigatePage} = useS115()

    const onClose = () => {
        setLocalDate((state: any) => ({
            ...state,
            isMenuOpen: false
        }))
    }

    return (
        <Styled.Single__Option
            onClick={() => {
                navigatePage(path)
                onClose()
            }}
        >
            {title}
        </Styled.Single__Option>
    )
}

export default SingleOption