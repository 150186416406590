import {Styled} from "./RentPageList.styles"
import {useTranslation} from "react-i18next";
import {RENT_PAGE} from "../../Routes";
import {
    faComputerSpeaker,
    faFireFlameCurved,
    faMicrophone,
    faSlidersUp,
    faSpeaker,
    faTurntable,
    faWaveform
} from "@fortawesome/pro-solid-svg-icons";
import useS115 from "../../lib/Hooks/useS115";

type ProductProps = {
    value: any
    label: string
}

const Product = (
    {
        label,
        value
    }: ProductProps
) => {

    const {t} = useTranslation()

    const {navigatePage} = useS115()

    const {
        image,
        producent,
        count,
        specialName,
        rentPrice,
        comboDesc,
        type,
        isSpecialPrice,
    } = value


    const getCustomLoader = () => {
        switch (type) {
            case 'Console':
                return faTurntable
            case 'Mixer':
                return faSlidersUp
            case 'Setup':
                return faComputerSpeaker
            case 'Speakers':
                return faSpeaker
            case 'MicroPhone':
                return faMicrophone
            default:
                return faWaveform
        }
    }

    const onProductClick = () => {
        navigatePage(`${RENT_PAGE}/${label}`)

    }

    const onImageClick = (event: any) => {
        // event.stopPropagation()
        //
    }


    return (
        <Styled.Product
            onClick={onProductClick}
        >
            <Styled.Product__Information__Holder>
                <Styled.Product__Label>
                    <span>
                        {`${!specialName ? label : t(`lbl_${label}`)} ${producent ? `• ${producent}` : ''}`}
                    </span>
                </Styled.Product__Label>
                <Styled.Short__Description>
                    {t(`lbl_${label}_desc`)}
                </Styled.Short__Description>
                <Styled.Price__Button__Holder>
                    <Styled.AvailAble__Count>
                        {t('lbl_available_count')}{': '}{count}
                    </Styled.AvailAble__Count>
                    <Styled.Price__Label>
                        {isSpecialPrice && (
                            <Styled.Flame__Price icon={faFireFlameCurved}/>
                        )}
                        <Styled.Price__Value__Holder
                            isSpecialPrice={isSpecialPrice}
                        >
                            {` ${rentPrice} PLN `}
                        </Styled.Price__Value__Holder>
                        {isSpecialPrice && (
                            <Styled.Flame__Price icon={faFireFlameCurved}/>
                        )}
                        {` ${t('lbl_rent_day')} `}

                    </Styled.Price__Label>
                </Styled.Price__Button__Holder>
            </Styled.Product__Information__Holder>
            <Styled.Product__Image
                customLoader={getCustomLoader()}
                src={image}
                onClick={onImageClick}
            />
        </Styled.Product>
    )
}

export default Product