export const RENT_PAGE = '/Wynajem'

export const MAIN_PAGE = '/'

export const REALISATION_PAGE = '/Realizacje'

export const STUDIO_PAGE = '/Studio'

export const CONTACT_PAGE = '/Kontakt'

export const SOCIAL_MEDIA = '/SocialMedia'

export const ABOUT_US_PAGE = '/Onas'

export const COMPANIES_PAGE = '/DlaFirm'

export const COMPANIES_LONG_TERM_RENT = `${COMPANIES_PAGE}/Najem`

export const COMPANIES_SERVICES = `${COMPANIES_PAGE}/Uslugi`