import React, {useEffect, useState} from "react";
import {Styled} from "./Image.styles";
import BrdLoader from "../BrdLoader";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {isEmpty} from "lodash-es";
import {faCameraSlash} from "@fortawesome/pro-light-svg-icons";

type ImageProps = {
    src: string
    customLoader?: IconProp
    customLoaderSize?: React.CSSProperties
    disableLoader?: boolean
    showIconWhenEmpty?: boolean
    customIconStyles?: React.CSSProperties
    customIcon?: IconProp
    onClick?: any
    style?: React.CSSProperties
}

const checkImageLoaded = (imageUrl: string) => {
    if (!isEmpty(imageUrl)) {
        const image = new Image();
        image.src = imageUrl;
        if (image.complete) {
            return true;
        }
        return false;
    }
    return true

};

const Img = (
    {
        customLoader,
        customLoaderSize,
        customIconStyles,
        onClick,
        customIcon,
        showIconWhenEmpty,
        src,
        disableLoader,
        ...props
    }: ImageProps
) => {

    const [isLoaded, setIsLoaded] = useState<boolean>(checkImageLoaded(src))

    const [imageElement, setImageElement] = useState<any>(null)

    const [imageWidth, setImageWidth] = useState<number>(imageElement?.clientWidth)

    const onLoaded = () => {
        setIsLoaded(true)
    }


    useEffect(() => {
        setImageWidth(imageElement?.clientWidth)
    }, [props])


    return (
        <Styled.Image__Holder
            srcEmpty={isEmpty(src)}
            onClick={onClick ? onClick : () => {
            }}
            ref={setImageElement}
            {...props}
        >
            {isEmpty(src) ? (
                <Styled.Empty__Src/>
            ) : (
                <Styled.Img
                    src={src}
                    onLoadCapture={() => {
                        setIsLoaded(false)
                    }}
                    onLoad={onLoaded}
                    {...props}
                />
            )}
            <Styled.Image__Loader__Holder
                elementWidth={imageWidth}
            >
                {!isLoaded && !disableLoader && (
                    <BrdLoader
                        customLoader={customLoader}
                        smallIconStyles={customLoaderSize}
                        style={{
                            margin: 'auto',
                        }}
                    />
                )}
                {showIconWhenEmpty && isLoaded && isEmpty(src) && (
                    <Styled.Custom__Icon
                        icon={customIcon ?? faCameraSlash}
                    />
                )}

            </Styled.Image__Loader__Holder>


        </Styled.Image__Holder>
    )
}
export default Img