import {Styled} from "./Animation.styles"
import {useContext, useEffect} from "react";
import {createPortal} from 'react-dom';
import useLockBodyScroll from "../../../lib/Hooks/useLockBodyScroll";
import {ANIMATION__CONTEXT} from "../../../Context";

const Animation = () => {

    const [isOn] = useContext<any>(ANIMATION__CONTEXT)

    const {setLocked} = useLockBodyScroll();

    useEffect(() => {
        setLocked(!!isOn)
    }, [])

    return isOn ? createPortal(
        <Styled.Animation__Navigation__Change
            isAnimate={isOn}
        >
            <Styled.Animation__Logo
                src={window.location.origin + '/S115Logo.png'}
                style={{
                    backgroundColor: 'transparent'
                }}
            />

        </Styled.Animation__Navigation__Change>,
        (document.querySelector('body') as HTMLElement)
    ) : null
}
export default Animation