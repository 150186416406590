import FlexBox from "../../lib/UIBookBrd/FlexBox";
import { Styled } from "./AboutUs.styles";
import Text from "../../lib/UIBookBrd/Text";
import {useTranslation} from "react-i18next";

const AboutUs = () =>{

    const {t} = useTranslation()

    return(
        <Styled.About__Holder>
            <Styled.About__Title>
                {t('lbl_why_us')}
            </Styled.About__Title>
            <Styled.About__Us__Section__Label>
                {t('lbl_short_about_us')}
            </Styled.About__Us__Section__Label>
            <Styled.About__Us__Description>
                {t('lbl_why_us_desc')}
            </Styled.About__Us__Description>
            <Styled.About__Us__Section__Label>
                {t('lbl_what_is_the_difference')}
            </Styled.About__Us__Section__Label>
            <Styled.About__Us__Description>
                {t('lbl_what_is_the_difference_desc')}
            </Styled.About__Us__Description>
            <Styled.About__Us__Section__Label>
                {t('lbl_where_are_we_from')}
            </Styled.About__Us__Section__Label>
            <Styled.About__Us__Description>
                {t('lbl_where_are_we_from_desc')}
            </Styled.About__Us__Description>
            <Styled.About__Us__Section__Label>
                {t('lbl_how_to_reserve')}
            </Styled.About__Us__Section__Label>
            <Styled.About__Us__Description>
                {t('lbl_how_to_reserve_desc')}
            </Styled.About__Us__Description>
        </Styled.About__Holder>
    )
}
export default AboutUs