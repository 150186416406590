import { Styled } from "./Navigation.styles"
import {MAIN_PAGE} from "../../../Routes";
import {useNavigate} from "react-router";
import {faBars, faBurger, faClose} from "@fortawesome/pro-solid-svg-icons";
import {useContext} from "react";
import {Local_Data} from "../../../Context";
import {languagesList} from "../../../PageValues";
import {useLocalStorage} from "react-use-storage";
import useS115 from "../../../lib/Hooks/useS115";

const Navigation = () =>{

    const [localData, setLocalDate] = useContext<any>(Local_Data);

    const [language, setLanguage] = useLocalStorage('language', 'pl')


    const{navigatePage}= useS115()

    const onMenuClick = () =>{
        if (!localData?.isMenuOpen){
        setLocalDate((state:any)=>({
                ...state,
                isMenuOpen: true
        }))
        }
    }

    return(
        <Styled.Navigation__Holder>
            <Styled.Content__Holder>
                <Styled.Navigation__Burger
                    onClick={onMenuClick}
                    icon={localData?.isMenuOpen ? faClose : faBars}
                />
            <Styled.Page__Title
            onClick={()=>{navigatePage(MAIN_PAGE)}}
            >
                S115
            </Styled.Page__Title>
                <Styled.Language__Bubble
                    onClick={()=>{
                        setLocalDate((state:any)=>({
                            ...state,
                            isOpeLanguage: true,
                        }))
                    }}
                src={languagesList.filter((item: any)=>item.type === language)[0]?.img}
                />
            </Styled.Content__Holder>

        </Styled.Navigation__Holder>
    )
}
export default Navigation