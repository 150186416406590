import styled from "styled-components";
import '../../../fonts.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Image from "../../../lib/UIBookBrd/Image";
const Navigation__Holder = styled.div`
  z-index: 20;
  top: 0;
  position: fixed;
  width: 100%;
  display: flex;
  background-color:${({theme}) => theme.backgroundColor}; ;
  border-bottom: 1px solid ${({theme}) => theme.color};
`
const Page__Title = styled.h1`
  padding: 0;
  cursor: pointer;
  display: flex;
  font-size: 45px;
  font-weight: bold;
  text-align: center;
  padding-left: 50px;
  height: 43px;
  color: ${({theme}) => theme.color};
margin: 15px auto;
  font-family: IconicS115;

`
const Content__Holder  =styled.div`
  flex-grow: 1;
display: flex;
  max-width: calc(1100px - 2vw);
  padding: 0 1vw;
  margin: 0 auto;
`
const Lanuage__Label = styled.span`

  color: ${({theme}) => theme.color};
`
const Navigation__Burger = styled(FontAwesomeIcon)`
color: ${({theme})=>theme.color};
  margin: 17px 0;
  cursor: pointer;
  height: 40px;
  width: 40px;
`
const Language__Bubble = styled(Image)`
width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: auto 0;
  cursor: pointer;

`
export const Styled = {
    Language__Bubble,
    Navigation__Burger,
    Content__Holder,
    Navigation__Holder,
    Page__Title,
    Lanuage__Label
}
