import Modal from "../../../lib/UIBookBrd/Modal/ModalsTypes/Modal";
import {useContext, useState} from "react";
import {Local_Data} from "../../../Context";
import {useTranslation} from "react-i18next";
import { Styled } from "./LanguageModal.styles";
import useOnClickOutside from "../../../lib/Hooks/useOnClickOutside";
import {languagesList} from "../../../PageValues";
import {faCheck} from "@fortawesome/pro-solid-svg-icons/faCheck";
import {useLocalStorage} from "react-use-storage";

const LanguageModal = () =>{

    const [localData, setLocalDate] = useContext<any>(Local_Data);

    const [elementRef, setElementRef] = useState<any>(null)

    const [language, setLanguage] = useLocalStorage('language', 'pl')

    const {t} = useTranslation()

    useOnClickOutside(elementRef, ()=>{
        setLocalDate((state:any)=>({
        ...state,
        isOpeLanguage: false
    }))})

    const onLangSelect = (lang: string) =>{
        setLocalDate((state:any)=>({
            ...state,
            isOpeLanguage: false
        }))
        if (lang !== language){
            setLanguage(lang)
            window.location.reload()
        }


    }

    return(
        <Modal
            title={t('lbl_language_choice')}
            isOpen={!!localData?.isOpeLanguage}
            width={800}
            ref={setElementRef}
            onClose={()=>{
                setLocalDate((state:any)=>({
                    ...state,
                    isOpeLanguage: false
                }))
            }}
        >
            <Styled.Languages__Holder>
                {languagesList?.map((option:any)=>{
                    const onClick = () =>{
                        onLangSelect(option.type)
                    }
                    return(
                        <Styled.Language__Option
                        onClick={onClick}
                        >
                            <Styled.Language__Image
                                src={option.img}
                            />
                            <Styled.Language__Label>
                                {option.label}
                            </Styled.Language__Label>
                            {option.type === localStorage.getItem('language')  && (
                                    <Styled.Language__Icon
                                    icon={faCheck}
                                    />
                            )}
                        </Styled.Language__Option>
                    )

                })}
            </Styled.Languages__Holder>
        </Modal>
    )
}
export default LanguageModal