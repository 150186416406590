import styled from "styled-components";

const Page__Holder = styled.div`
  max-width: 1100px;
  height: 100%;
min-height: calc(100% + 73px);
  @media(min-width: 1100px){
    margin: 0 auto;
  }
`
const Page = styled.div`
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/studioskar.appspot.com/o/S115%20yt%20baner.png?alt=media&token=c6640735-27fb-48a1-a6f1-6b0291a169a8') ;
  z-index: 10;
  width: 100%;
  min-height: calc(100vh - 75px);
  
`
const Page__Title = styled.h1`
  padding: 0;
  cursor: pointer;
  display: flex;
  font-size: 45px;
  font-weight: bold;
  text-align: center;
  height: 43px;
  color: ${({theme}) => theme.color};
margin: auto;
  margin-bottom: 0;
  font-family: IconicS115;
`
const Coming__Soon__Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`
const Coming__Soon__Label = styled.h2`
  font-size: 30px;
  color: ${({theme}) => theme.color};
margin: 10px auto auto auto;
`

export const Styled = {
    Coming__Soon__Label,
    Coming__Soon__Page,
    Page__Title,
    Page__Holder,
    Page
}
