import {Styled} from "./BrdLoader.styles"
import Text from "../Text";
import React from "react";
import FlexBox from "../FlexBox";
import {useTranslation} from "react-i18next";
import {faPlane} from "@fortawesome/pro-solid-svg-icons/faPlane";
import {isEmpty} from "lodash-es";
import {faTurntable} from "@fortawesome/pro-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

type BrdLoaderProps = {
    customLoader?: IconProp
    smallIconStyles?: React.CSSProperties
    isBig?: boolean
    style?: React.CSSProperties
    label?: string
}
const defaultProps = {
    isBig: false,
    style: undefined,
    label: ''
}

const BrdLoader = ({
                       smallIconStyles,
                       isBig,
                       style,
                       customLoader,
                       label
                   }: BrdLoaderProps) => {
    const {t} = useTranslation();
    return (
        <FlexBox
            style={{
                ...style,
                flexDirection: 'column',
                textAlign: 'center',
            }}
        >
            {!isEmpty(label) && (
                <Text>{t(`${label}`)}</Text>
            )}
            {isBig ?
                <Styled.BrdLoader>
                    <Styled.LoaderTextBold
                        isBig={isBig}
                    >
                        Book
                    </Styled.LoaderTextBold>
                    <Styled.LoaderTextLighter
                        isBig={isBig}
                    >
                        Brd
                    </Styled.LoaderTextLighter>
                </Styled.BrdLoader>
                : (

                    <Styled.FontAwesomeIcons
                        isBig={isBig as boolean}
                        icon={customLoader ?? faTurntable}
                        style={smallIconStyles}
                    />
                )}
        </FlexBox>
    )
}
BrdLoader.defaultProps = defaultProps;
export default BrdLoader