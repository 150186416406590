import { Styled } from "./7Studio.styles"
import Navigation from "./Compnents/Navigation";
import MainPage from "./MainPage";
import {Route, Routes, useLocation} from "react-router-dom";
import {ABOUT_US_PAGE, CONTACT_PAGE, MAIN_PAGE, RENT_PAGE, STUDIO_PAGE} from "../Routes";
import RentPageList from "./RentPageList";
import ProductPage from "./ProductPage";
import MenuDrawer from "./Compnents/MenuDrawer";
import LanguageModal from "./Compnents/LanguageModal";
import ContactPage from "./Contact";
import AboutUs from "./AboutUs";
import {useContext, useEffect} from "react";
import {Local_Data} from "../Context";
import useMetaForS115 from "../lib/Hooks/useMetaForS115";
import {isEmpty} from "lodash-es";
import Studio from "./Studio";



const Whole__Page  = () =>{

    const {getMetaForSEO} = useMetaForS115()

    const location = useLocation()

    useEffect(()=>{
        getMetaForSEO()
    },[location])


    return(
        <Styled.Page>
            <img
            src={'https://firebasestorage.googleapis.com/v0/b/studioskar.appspot.com/o/S115%20logo%20yt.jpg?alt=media&token=9f4f11f6-bc2b-4b3d-a75c-30c16e288f1f'}
            alt={'S115 Company Image'}
            style={{
                visibility:'hidden',
                height:'1px',
                width:'1px',
                zIndex:'-999',
                position:'absolute'
            }}
            />
                <Navigation/>
            <Styled.Page__Holder>
                <Routes>
                    <Route
                        path={MAIN_PAGE}
                        element={(<MainPage/>)}
                    />
                    <Route
                        path={RENT_PAGE}
                        element={(<RentPageList/>)}
                    />
                    <Route
                        path={`${RENT_PAGE}/:productName`}
                        element={(<ProductPage/>)}
                    />
                    <Route
                        path={STUDIO_PAGE}
                        element={<Studio/>}
                    />
                    <Route
                        path={ABOUT_US_PAGE}
                        element={<AboutUs/>}
                    />
                    <Route
                        path={CONTACT_PAGE}
                        element={<ContactPage/>}
                    />

                </Routes>
            </Styled.Page__Holder>
            <MenuDrawer/>
            <LanguageModal/>
        </Styled.Page>
    )
}

export default Whole__Page