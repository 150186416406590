import styled from "styled-components";
import Image from "../../lib/UIBookBrd/Image";
import Text from "../../lib/UIBookBrd/Text";

const Page__Hodler = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 73px;
  height: auto;
   `
const Content__Holder = styled.div`
display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${({theme})=>theme.backgroundColor};
  padding-bottom: 30px;
`
const Studio__Image = styled(Image)`
  width: 100%;
  object-fit: cover;
  object-position: center, 40%;
  height: 400px;
  
  @media(max-width: 800px){
    height: 300px;
  }
  @media(max-width: 500px){
    height: 200px;
  }
  
`
const H2__Studio = styled.h2`
margin: 10px;
color: ${({theme})=>theme.color};;
`

const Studio__Text = styled(Text)`
margin: 2px 5px;
`

export const Styled = {
    Studio__Text,
    H2__Studio,
    Studio__Image,
    Page__Hodler,
    Content__Holder
}
