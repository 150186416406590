import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import styled from "styled-components";
import Text from "../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Menu__Drawer__Container = styled(FlexBox)`
  flex-direction: column;
  min-width: 400px;
  margin: 10px 0;
  gap: 2px 5px;
  @media (max-width: 400px) {
    width: 100%;
  }
`
const Menu__Drawer__Section__Part = styled(FlexBox)`
  flex-direction: column;
  width: calc(100% - 10px);
  margin: 0 5px;
  border-radius: 5px;
  background-color: ${({theme}) => theme.OfferListBackgroundColor};

`
const Section__Title__Holder = styled(FlexBox)<{ isOpen: boolean }>`
  flex-wrap: nowrap;
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid ${({theme, isOpen}) => isOpen ? theme.color : 'transparent'};

`
const Section__Title = styled(Text)`
  font-size: 20px;
  font-weight: bold;
`
const Section__Status__Chevron = styled(FontAwesomeIcon)`
  color: ${({theme}) => theme.color};
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 20px;
  width: 20px;
`
const Section__Values__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Section__Option = styled(Text)<{ isLast: boolean }>`
  font-size: 20px;
  cursor: pointer;
  padding: 10px 10px;
  padding-left: 20px;
  border-bottom: 1px solid ${({theme, isLast}) => isLast ? 'transparent' : theme.color};
`
const Page__Title = styled.h1`
  padding: 0;
  cursor: pointer;
  display: flex;
  height: 10px;
  margin-top: -0px;
  margin-left: 10px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: ${({theme}) => theme.color};
  font-family: IconicS115;
`
const Single__Option = styled(Text)`
  font-size: 25px;
  font-weight: bold;
  margin: 5px;
  padding: 10px;
  transition: 200ms;
  cursor: pointer;
  border-radius: 10px;
  line-height: unset;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid transparent;
  }
`


export const Styled = {
    Single__Option,
    Page__Title,
    Section__Option,
    Section__Values__Holder,
    Section__Status__Chevron,
    Section__Title,
    Menu__Drawer__Container,
    Menu__Drawer__Section__Part,
    Section__Title__Holder
}