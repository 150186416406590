import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Text from "../../lib/UIBookBrd/Text";
import {rgba} from "polished";

const Contact__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  text-align: center;
  min-height: calc(100vh - 80px);
  margin-top: 73px;
  background-color: ${({theme}) => rgba(theme.backgroundColor, 0.1)};
  padding-bottom: 10px;
`
const Contact__Section__Title = styled(Text)`
  padding: 40px 5px;
  font-size: 45px;
  font-weight: bold;
  text-align: center;
`
const Type__Title = styled(Text)`
  padding: 10px 5px;
  font-size: 10px;
  font-weight: bold;
`
const Contact__Phone__Number = styled(Text)`
  font-size: 26px;
  margin: 4px 0;
  margin-left: 15px;
  line-height: 29px;
`

export const Styled = {
    Contact__Phone__Number,
    Type__Title,
    Contact__Section__Title,
    Contact__Holder
}