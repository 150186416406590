import styled from "styled-components";

const MainPage = styled.div`
  margin-top: 75px;
display: flex;
flex-direction: column;
  flex-wrap: nowrap;
  height: auto;
  min-height: calc(100vh - 75px);
  
`
export const Styled = {
    MainPage
}